import React, { useState, useEffect } from "react";
import { performOCR } from "./PerformOCR";
import performVISION from "./PerformVISION";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { authenticated } from "../../utils/amplify";

const OCRApp = () => {
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const authStatus = await authenticated();
      setIsAuthenticated(authStatus);
      // console.log("認証状態:", authStatus ? "ログイン中" : "未ログイン");
    };
    checkAuth();
  }, []);

  // ファイル選択または撮影
  const handleCapture = (event) => {
    const capturedFile = event.target.files[0];
    if (!capturedFile) return;

    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
    ];
    if (!allowedFormats.includes(capturedFile.type)) {
      setErrorMessage(
        `アップロードできません。「${capturedFile.name}」は対応していない形式です。`
      );
      return;
    }

    if (!(capturedFile instanceof Blob)) {
      console.error("Captured file is not a valid Blob:", capturedFile);
      setErrorMessage("選択されたファイルが不正です。");
      return;
    }

    setImage(capturedFile);
    setText("");
    setErrorMessage("");
  };

  // OCR実行処理
  const handleOCR = async () => {
    if (!image) return;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(image);

    fileReader.onload = async (e) => {
      const imageURL = e.target.result;
      setErrorMessage("");
      setIsLoading(true);
      setProgress(0);

      try {
        const resultText = await performOCR(imageURL, setProgress);
        setText(resultText);
      } catch (error) {
        console.error("OCRエラー詳細:", error);
        setErrorMessage("OCR処理中にエラーが発生しました。");
      } finally {
        setIsLoading(false);
      }
    };
  };

  // Vision実行処理
  const handleVISION = async () => {
    if (!image || !isAuthenticated) return;

    setIsLoading(true);
    setErrorMessage("");
    setProgress(0);

    try {
      // ファイルをperformVISIONに渡す
      const resultText = await performVISION(image, setProgress);
      setText(resultText);
      // console.log(resultText);
    } catch (error) {
      console.error("Visionエラー詳細:", error);
      setErrorMessage("Vision処理中にエラーが発生しました。");
    } finally {
      setIsLoading(false);
    }
  };

  // 画像削除処理
  const handleClearImage = () => {
    setImage(null); // 画像の状態をリセット
    setText(""); // 文字起こし結果をリセット
    setErrorMessage(""); // エラーメッセージをリセット
    // console.log("画像が削除されました");
  };

  // OCR結果の保存
  const handleSave = () => {
    const blob = new Blob([text], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ocr_result.txt";
    link.click();
  };

  // OCR結果のコピー
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    alert("コピーしました！");
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 1200, margin: "auto" }}>
      <Typography variant="h5" gutterBottom textAlign="center">
        手書き文字の文字起こし & 編集
      </Typography>

      <Grid container spacing={2} mt={2}>
        {/* 左側: プレビュー */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6" gutterBottom>
                  画像を用意する
                </Typography>
                <label htmlFor="file-input">
                  <input
                    accept="image/*"
                    capture="environment"
                    id="file-input"
                    type="file"
                    hidden
                    onChange={handleCapture}
                  />
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton color="primary" component="span">
                      <FileUploadIcon />
                    </IconButton>
                    <Typography variant="body2" color="textSecondary">
                      アップロードする
                    </Typography>
                  </Stack>
                </label>
              </Stack>
              {image ? (
                <img
                  src={URL.createObjectURL(image)}
                  alt="Preview"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "calc(100vh - 250px)",
                    borderRadius: "8px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Box>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    「アップロードする」アイコンより、画像データをアップロードしてください。
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    PCをお使いの場合は、あらかじめ対象の書類の写真を撮影し、写真データをPCに保存してからアップロードしてください。
                  </Typography>
                  <Typography variant="body2" color="textSecondary" paragraph>
                    英字の文字起こしを行いますので、アルファベット以外は正しく文字起こしされません。
                  </Typography>

                  <Typography variant="body2" color="textSecondary" paragraph>
                    対応しているファイル形式は以下の通りです:
                    <br />
                    <strong>JPEG, PNG, GIF, BMP, WEBP</strong>
                  </Typography>

                  {errorMessage && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ mt: 2, textAlign: "left" }}
                    >
                      {errorMessage}
                    </Typography>
                  )}

                  <Paper
                    elevation={0}
                    sx={{
                      padding: 2,
                      backgroundColor: "#f3f6fa",
                      borderRadius: "8px",
                      border: "1px solid #e0e0e0",
                      mt: 2,
                    }}
                  >
                    <Typography variant="body2" color="textPrimary" paragraph>
                      <b>文字起こし機能</b>
                      <br />
                      <lo>
                        <li>
                          この文字起こし機能は、アップロードされた画像データを外部サーバーに送信することなく、お使いのブラウザ内で直接処理を行います。
                        </li>
                        <li>どなたでもご利用いただけます。</li>
                        <li>扱える画像サイズ：最大20MB</li>
                      </lo>
                    </Typography>
                    <Typography variant="body2" color="textPrimary" paragraph>
                      <b>高精度文字起こし機能</b>
                      <br />
                      <lo>
                        <li>
                          高精度文字起こし機能は、高精度で文字起こしを行いますが、アップロードされた画像データを外部サーバーに送信します。
                        </li>
                        <li>個人情報を含む画像を使用しないでください。</li>
                        <li>
                          アカウントを持っているユーザーのみご利用いただけます。
                        </li>
                        <li>扱える画像サイズ：最大10MB</li>
                      </lo>
                    </Typography>
                  </Paper>
                </Box>
              )}

              <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOCR}
                  disabled={!image || isLoading}
                >
                  文字起こし
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleVISION}
                  disabled={!isAuthenticated || !image || isLoading}
                >
                  高精度文字起こし
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleClearImage}
                  disabled={!image || isLoading}
                >
                  画像削除
                </Button>
              </Stack>
              {isLoading && (
                <Box sx={{ mt: 2 }}>
                  <CircularProgress variant="determinate" value={progress} />
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {progress}%
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>

        {/* 右側: OCR結果 */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                文字起こし結果
              </Typography>
              <TextField
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={20}
                fullWidth
                variant="outlined"
              />
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                mt={2}
              >
                <Button
                  variant="outlined"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopy}
                  color="info"
                >
                  コピー
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<SaveAltIcon />}
                  onClick={handleSave}
                  color="success"
                >
                  保存
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OCRApp;
