import React, { useState } from "react";
import { Button, Grid, TextField, Box } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import SelectMenuButtonforEssay from "../EssaySupport/SelectMenusAndButtonforEssay";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

const replacePlaceholders = (template, replacements) => {
  return template.replace(
    /\{\{(\w+)\}\}/g,
    (_, key) => replacements[key] || ""
  );
};

const markdownStyles = {
  h1: { fontSize: 24, fontWeight: "bold" },
  p: { marginBottom: 1.5, lineHeight: 1.5 },
  li: { marginLeft: 2, marginBottom: 1 },
  strong: { fontWeight: "bold" },
  table: { width: "100%", borderCollapse: "collapse" },
  th: { border: "1px solid #ddd", padding: "8px", backgroundColor: "#f2f2f2" },
  td: { border: "1px solid #ddd", padding: "8px" },
  img: { maxWidth: "100%", height: "auto" },
};

export default function App({ questionType }) {
  const [loading, setLoading] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");

  const handleChatGPT = async () => {
    setLoading(true);

    const essayEnglish = document.getElementById("EnglishC3").value;
    const essayValuationText =
      document.getElementById("C3_essayEvaluation").value;

    // Abort if no text is input
    if (
      !essayValuationText ||
      essayValuationText.trim() === "" ||
      essayValuationText.includes("⚠️")
    ) {
      setMarkdownContent("　⚠️ 評価結果が正しく出力されていますか？");
      setLoading(false);
      return;
    }
    let API_ENDPOINT = APILambda.chatGpt;
    let headers = {};

    if (await authenticated()) {
      API_ENDPOINT = APIGateWay.chatGpt;
      headers = {
        Authorization: "Bearer " + (await getToken()),
      };
    }

    const requestBody = JSON.stringify({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "system",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_system
          ),
        },
        {
          role: "assistant",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_assistant
          ),
        },
        {
          role: "user",
          content: replacePlaceholders(
            process.env.REACT_APP_ChatGPTSetting_essayopinion_1_user,
            { essayEnglish, essayValuationText }
          ),
        },
      ],
      temperature: 0.1,
    });

    try {
      const response = await fetch(API_ENDPOINT, {
        method: "POST",
        headers,
        body: requestBody,
      });
      if (!response.ok) {
        throw new Error("Network response was NOT ok");
      }
      const data = await response.json();
      setMarkdownContent(data.choices[0].message.content);
    } catch (error) {
      setMarkdownContent("　⚠️ エラーが発生しました");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container sx={{ padding: 0, position: "relative" }}>
      <Grid item xs={12} md={6} sx={{ marginTop: 0, marginBottom: 0 }}>
        <SelectMenuButtonforEssay questionType={questionType} />
        <TextField
          fullWidth
          multiline
          minRows={8}
          maxRows={16}
          InputProps={{
            style: {
              background: "#f1f3f4",
              fontSize: 16,
              lineHeight: 1.5,
              borderTopLeftRadius: "0",
            },
            readOnly: true,
          }}
          id="C3_essayEvaluation"
          label=""
          placeholder="評価結果が表示されます"
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{ marginTop: 1, marginBottom: 0, position: "relative" }}
      >
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={handleChatGPT}
          startIcon={<TipsAndUpdatesIcon />}
          width="50%"
          sx={{
            paddingBlock: 0.75,
            fontSize: 14,
            textTransform: "capitalize",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
          disabled={loading}
        >
          {loading ? "読み込み中..." : "意見をもらう"}
        </Button>
        <Box
          elevation={0}
          sx={{
            margin: 0,
            background: "#f1f3f4",
            "& .MuiOutlinedInput-notchedOutline": {
              borderTop: 0,
            },
            "& :hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#c5c5c5",
            },
            "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: 1,
              borderColor: "#c5c5c5",
            },
            padding: 2,
            border: "1px solid #c5c5c5",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "0",
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            minHeight: "225px",
            maxHeight: "617px",
            overflowY: "auto",
            fontSize: 16,
            userSelect: "none", // 追加部分
          }}
        >
          {/* Markdownコンテンツ */}
          <ReactMarkdown
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({ node, ...props }) => (
                <Box
                  component="h1"
                  sx={{ ...markdownStyles.h1, userSelect: "none" }}
                  {...props}
                />
              ),
              p: ({ node, ...props }) => (
                <Box
                  component="p"
                  sx={{ ...markdownStyles.p, userSelect: "none" }}
                  {...props}
                />
              ),
              li: ({ node, ...props }) => (
                <Box
                  component="li"
                  sx={{ ...markdownStyles.li, userSelect: "none" }}
                  {...props}
                />
              ),
              strong: ({ node, ...props }) => (
                <Box
                  component="strong"
                  sx={{ ...markdownStyles.strong, userSelect: "none" }}
                  {...props}
                />
              ),
              table: ({ node, ...props }) => (
                <Box
                  component="table"
                  sx={{ ...markdownStyles.table, userSelect: "none" }}
                  {...props}
                />
              ),
              th: ({ node, ...props }) => (
                <Box
                  component="th"
                  sx={{ ...markdownStyles.th, userSelect: "none" }}
                  {...props}
                />
              ),
              td: ({ node, ...props }) => (
                <Box
                  component="td"
                  sx={{ ...markdownStyles.td, userSelect: "none" }}
                  {...props}
                />
              ),
              img: ({ node, ...props }) => (
                <Box
                  component="img"
                  sx={{ ...markdownStyles.img, userSelect: "none" }}
                  {...props}
                />
              ),
            }}
          >
            {markdownContent}
          </ReactMarkdown>
        </Box>
      </Grid>
    </Grid>
  );
}
