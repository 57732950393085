import Tesseract from "tesseract.js";

/**
 * 画像を20MB以下にリサイズ
 * @param {File} file - アップロードされた画像ファイル
 * @param {function} callback - リサイズ後の処理を行うコールバック
 */
const resizeTo20MB = (file, callback) => {
  const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB
  const img = new Image();
  const reader = new FileReader();

  reader.onload = (e) => {
    img.src = e.target.result;
  };

  img.onload = () => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    let { width, height } = img;

    // 繰り返しリサイズ処理を実行
    let quality = 1.0; // 初期品質
    let resizedDataURL;
    do {
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // JPEG形式でエンコード（他の形式も必要に応じて対応可）
      resizedDataURL = canvas.toDataURL("image/jpeg", quality);

      // 品質を下げるために 0.1 減少（画像が20MB以下になるまで）
      quality -= 0.1;

      // 幅と高さも縮小（長辺を10%縮小）
      width *= 0.9;
      height *= 0.9;
    } while (resizedDataURL.length > MAX_FILE_SIZE && quality > 0);

    callback(resizedDataURL);
  };

  reader.readAsDataURL(file);
};

/**
 * 画像の前処理（バイナリ化）
 * @param {HTMLImageElement} image - 画像要素
 * @returns {string} Data URL形式のバイナリ化画像
 */
const preprocessImage = (image) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  canvas.width = image.width;
  canvas.height = image.height;

  // 画像を描画
  ctx.drawImage(image, 0, 0);

  // グレースケール化 & バイナリ化
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;

  for (let i = 0; i < data.length; i += 4) {
    const grayscale = 0.3 * data[i] + 0.59 * data[i + 1] + 0.11 * data[i + 2];
    const binary = grayscale > 128 ? 255 : 0; // しきい値: 128
    data[i] = data[i + 1] = data[i + 2] = binary; // RGBに同じ値を適用
  }

  ctx.putImageData(imageData, 0, 0);
  return canvas.toDataURL(); // Data URL形式で返す
};

/**
 * OCR処理を実行
 * @param {string} imageURL - OCR対象の画像URL
 * @param {function} onProgress - 進捗状況を通知するコールバック関数
 * @returns {Promise<string>} OCR結果の文字列
 */
export const performOCR = async (imageURL, onProgress) => {
  try {
    const img = new Image();
    img.src = imageURL;

    // 画像の読み込み完了を待つ
    await new Promise((resolve) => (img.onload = resolve));

    // 前処理
    const preprocessedImageURL = preprocessImage(img);

    // Tesseract OCR実行
    const result = await Tesseract.recognize(preprocessedImageURL, "eng", {
      tessedit_char_whitelist:
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      psm: 6, // 段落単位で認識
      logger: (info) => {
        if (info.status === "recognizing text" && onProgress) {
          onProgress(Math.floor(info.progress * 100));
        }
      },
    });

    return result.data.text; // 認識結果を返す
  } catch (error) {
    throw new Error("OCR処理中にエラーが発生しました。");
  }
};

/**
 * ファイルをOCRに渡す前にリサイズ処理
 * @param {File} file - アップロードされたファイル
 * @param {function} onProgress - 進捗状況のコールバック
 * @returns {Promise<string>} OCR結果の文字列
 */
export const processFile = async (file, onProgress) => {
  return new Promise((resolve, reject) => {
    resizeTo20MB(file, async (resizedDataURL) => {
      try {
        const text = await performOCR(resizedDataURL, onProgress);
        resolve(text);
      } catch (error) {
        reject(error);
      }
    });
  });
};
