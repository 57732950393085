import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Paper,
  Chip,
  Tooltip,
  Grid,
  FormControlLabel,
  Switch,
  LinearProgress,
  IconButton,
} from "@mui/material";
import { Tag } from "@mui/icons-material";

import InfoIcon from "@mui/icons-material/Info";

function EnglishWriteHelper() {
  const [text, setText] = useState("");
  const [totalWords, setTotalWords] = useState(0);
  const [uniqueWords, setUniqueWords] = useState(0);
  const [totalCharacters, setTotalCharacters] = useState(0);
  const [totalSentences, setTotalSentences] = useState(0);
  const [totalParagraphs, setTotalParagraphs] = useState(0);
  const [wordCounts, setWordCounts] = useState([]);
  const [sortByCount, setSortByCount] = useState(true); // 初期状態は頻度順

  // 目標値の管理
  const [targetWords, setTargetWords] = useState(200);
  const [targetUniqueWords, setTargetUniqueWords] = useState(150);
  const [targetCharacters, setTargetCharacters] = useState(1000);
  const [targetSentences, setTargetSentences] = useState(15);
  const [targetParagraphs, setTargetParagraphs] = useState(1);

  // テキスト変更時の処理
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);

    // 単語の分割、記号削除
    const words = newText
      .trim()
      .toLowerCase()
      .split(/\s+/)
      .map((word) => word.replace(/[^\w]/g, ""));

    const wordFrequency = words.reduce((acc, word) => {
      if (word) acc[word] = (acc[word] || 0) + 1;
      return acc;
    }, {});

    // 文数を計算
    const sentences = newText
      .split(/[.!?]+/)
      .filter((sentence) => sentence.trim());

    // 段落数を計算
    const paragraphs = newText
      .split(/\n+/)
      .filter((paragraph) => paragraph.trim());

    // 結果をセット
    setTotalWords(words.filter((word) => word).length);
    setUniqueWords(new Set(words.filter((word) => word)).size);
    setTotalCharacters(newText.replace(/\s/g, "").length); // 空白を除いた文字数
    setTotalSentences(sentences.length);
    setTotalParagraphs(paragraphs.length);
    setWordCounts(Object.entries(wordFrequency));
  };

  // ソートロジック
  const sortedWordCounts = [...wordCounts].sort((a, b) => {
    if (sortByCount) {
      return b[1] - a[1]; // 頻度の降順
    } else {
      return a[0].localeCompare(b[0]); // アルファベット順
    }
  });

  // 各指標の進捗率
  const wordProgress = Math.min((totalWords / targetWords) * 100, 100);
  const uniqueWordProgress = Math.min(
    (uniqueWords / targetUniqueWords) * 100,
    100
  );
  const characterProgress = Math.min(
    (totalCharacters / targetCharacters) * 100,
    100
  );
  const sentenceProgress = Math.min(
    (totalSentences / targetSentences) * 100,
    100
  );
  const paragraphProgress = Math.min(
    (totalParagraphs / targetParagraphs) * 100,
    100
  );

  return (
    <Box sx={{ p: 3, maxWidth: 900, margin: "auto" }}>
      <Typography variant="h4" gutterBottom align="center">
        異なり語数計算アプリ / English Writing Helper
        <Tooltip title="開発コメント：これはベータ版です。予告せず削除することもあります。ニーズがあれば残します。必要な機能があれば画面右下の「問い合わせフォーム」よりお知らせください。ダウンロード機能欲しいとかありますか？">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Typography>

      <TextField
        label="Write your text here"
        multiline
        rows={8}
        variant="outlined"
        fullWidth
        value={text}
        onChange={handleTextChange}
        sx={{ mb: 3 }}
        helperText="Try to use diverse words to improve your writing!"
      />

      <Paper sx={{ p: 3, textAlign: "center", mb: 3 }}>
        <Typography variant="h6">Summary</Typography>

        {/* Total Words */}
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Total Words:
            </Typography>
            <Typography variant="caption">{totalWords} words /</Typography>
            <TextField
              type="number"
              variant="standard"
              value={targetWords}
              onChange={(e) => setTargetWords(Number(e.target.value))}
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: "center" } },
              }}
              sx={{ width: 50 }}
            />
            <Typography variant="caption">
              words ({wordProgress.toFixed(0)}%)
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={wordProgress}
            sx={{ my: 1 }}
          />
        </Box>

        {/* Unique Words */}
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Unique Words:
            </Typography>
            <Typography variant="caption">{uniqueWords} words /</Typography>
            <TextField
              type="number"
              variant="standard"
              value={targetUniqueWords}
              onChange={(e) => setTargetUniqueWords(Number(e.target.value))}
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: "center" } },
              }}
              sx={{ width: 50 }}
            />
            <Typography variant="caption">
              words ({uniqueWordProgress.toFixed(0)}%)
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={uniqueWordProgress}
            sx={{ my: 1 }}
          />
        </Box>

        {/* Characters */}
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Characters:
            </Typography>
            <Typography variant="caption">
              {totalCharacters} characters /
            </Typography>
            <TextField
              type="number"
              variant="standard"
              value={targetCharacters}
              onChange={(e) => setTargetCharacters(Number(e.target.value))}
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: "center" } },
              }}
              sx={{ width: 50 }}
            />
            <Typography variant="caption">
              characters ({characterProgress.toFixed(0)}%)
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={characterProgress}
            sx={{ my: 1 }}
          />
        </Box>

        {/* Sentences */}
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Sentences:
            </Typography>
            <Typography variant="caption">
              {totalSentences} sentences /
            </Typography>
            <TextField
              type="number"
              variant="standard"
              value={targetSentences}
              onChange={(e) => setTargetSentences(Number(e.target.value))}
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: "center" } },
              }}
              sx={{ width: 50 }}
            />
            <Typography variant="caption">
              sentences ({sentenceProgress.toFixed(0)}%)
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={sentenceProgress}
            sx={{ my: 1 }}
          />
        </Box>

        {/* Paragraphs */}
        <Box sx={{ mt: 3, mb: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Paragraphs:
            </Typography>
            <Typography variant="caption">
              {totalParagraphs} paragraphs /
            </Typography>
            <TextField
              type="number"
              variant="standard"
              value={targetParagraphs}
              onChange={(e) => setTargetParagraphs(Number(e.target.value))}
              size="small"
              InputProps={{
                inputProps: { style: { textAlign: "center" } },
              }}
              sx={{ width: 50 }}
            />
            <Typography variant="caption">
              paragraphs ({paragraphProgress.toFixed(0)}%)
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={paragraphProgress}
            sx={{ my: 1 }}
          />
        </Box>
      </Paper>

      <Paper sx={{ p: 3, textAlign: "left", mb: 3 }}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Frequently Used Words</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={sortByCount}
                onChange={() => setSortByCount(!sortByCount)}
                color="primary"
              />
            }
            label={sortByCount ? "Sort by Frequency" : "Sort by Alphabet"}
            labelPlacement="start"
          />
        </Grid>
        {sortedWordCounts.length > 0 ? (
          sortedWordCounts.map(([word, count], index) => (
            <Tooltip title={`${count} times`} key={index}>
              <Chip
                label={`${word} (${count})`}
                icon={<Tag />}
                sx={{ m: 0.5 }}
                color={count > 1 ? "primary" : "default"}
              />
            </Tooltip>
          ))
        ) : (
          <Typography>No words detected yet.</Typography>
        )}
      </Paper>
    </Box>
  );
}

export default EnglishWriteHelper;
