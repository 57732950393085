import { APIGateWay, APILambda } from "../../constants/api";
import { getToken, authenticated } from "../../utils/amplify";

/**
 * 画像をBase64にエンコードする関数
 * @param {File} file - アップロードされた画像ファイル
 * @returns {Promise<string>} - Base64エンコードされたデータURL
 */
const encodeImageToBase64 = (file) => {
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();
    // reader.onload = () => resolve(reader.result); // Base64データURL

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let { width, height } = img;

      let quality = 1.0; // 初期品質
      let resizedDataURL;

      // リサイズと品質調整で10MB以下に抑える
      do {
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        resizedDataURL = canvas.toDataURL("image/jpeg", quality);

        // サイズと品質を調整
        quality -= 0.1; // 品質を10%ずつ下げる
        width *= 0.9; // 横幅を10%縮小
        height *= 0.9; // 縦幅を10%縮小
      } while (
        resizedDataURL.length > MAX_FILE_SIZE &&
        quality > 0.1 &&
        width > 100 &&
        height > 100
      );

      // 最終結果を返す
      if (resizedDataURL.length > MAX_FILE_SIZE) {
        reject(new Error("画像サイズが10MB以下に収まりませんでした。"));
      } else {
        resolve(resizedDataURL);
      }
    };

    reader.onerror = (error) =>
      reject("画像の読み込みに失敗しました: " + error);
    reader.readAsDataURL(file);
  });
};

/**
 * OpenAI Vision APIを呼び出す関数
 * @param {File} image - アップロードされた画像ファイル
 * @returns {Promise<string>} - 認識結果のテキスト
 */

// OpenAI Vision APIを呼び出す関数
const performVISION = async (image, onProgress) => {
  try {
    const isAuthenticated = await authenticated();
    if (!isAuthenticated) {
      throw new Error("認証されていません。ログインが必要です。");
    }
    const API_ENDPOINT = (await authenticated())
      ? APIGateWay.chatGpt
      : APILambda.chatGpt;
    const headers = (await authenticated())
      ? { Authorization: await getToken() }
      : {};

    onProgress(20);

    // 画像をBase64にエンコード
    const base64Image = await encodeImageToBase64(image);
    // console.log("Base64エンコードされた画像:", base64Image);

    onProgress(50);

    // リクエストボディの設定
    const requestBody = JSON.stringify({
      model: "gpt-4o-mini", // 正しいモデル名
      messages: [
        // { role: "system", content: "You are a helpful assistant." },
        {
          role: "user",
          //   content: "日本について教えて.",
          content: [
            {
              type: "text",
              text: "画像に書かれている文字を書き出せ。結果は書き出した文字だけを表示して。",
            },
            {
              type: "image_url",
              //   image_url: { url: image }, // 渡された画像URL
              image_url: {
                // url: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Gfp-wisconsin-madison-the-nature-boardwalk.jpg/2560px-Gfp-wisconsin-madison-the-nature-boardwalk.jpg",
                url: base64Image,
              },
            },
          ],
        },
      ],
      temperature: 0.0, // 温度パラメータ
    });

    onProgress(70);

    // APIリクエストの送信
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers,
      body: requestBody,
    });

    onProgress(90);

    // レスポンスの確認
    if (!response.ok) {
      const errorResponse = await response.text();
      throw new Error(`OpenAI APIエラー: ${errorResponse}`);
    }

    // レスポンスのパース
    const data = await response.json();
    // console.log("APIレスポンス:", data);
    onProgress(100);

    // 結果テキストを取得
    const resultText =
      data.choices?.[0]?.message?.content || "結果が取得できませんでした。";
    return resultText;
  } catch (error) {
    console.error("Vision APIエラー:", error);
    throw new Error("Vision APIの呼び出し中にエラーが発生しました。");
  }
};

export default performVISION;
